import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { RedButton } from "./button-red"

export class BannerBottom extends Component {
    render() {

        const leftHalfCircle = {
            position: "absolute",
            width: "2in",
            height: "3.5in",
            top: "0",
            left: "0",
            background: "#FFFFFF",
            opacity: "0.1",
            borderRadius: "100% 0 0 100%",
            transform: "translateY(1%) scaleX(-1)",
            zIndex: "20"
        }

        const rightHalfCircle = {
            position: "absolute",
            width: "3.5in",
            height: "3.5in",
            bottom: "0",
            right: "10",
            background: "#FFFFFF",
            opacity: "0.1",
            borderRadius: "100% 0 0 100%",
            transform: "translateY(10%) translateX(-30%) scaleX(1)",
            zIndex: "-1"
        }

        const mobileRightHalfCircle = {
            position: "absolute",
            width: "3.5in",
            height: "3.5in",
            bottom: "0",
            right: "10",
            background: "#FFFFFF",
            opacity: "0.1",
            borderRadius: "100% 0 0 100%",
            transform: "translateY(-1%) translateX(-50%) scaleX(-1)",
            zIndex: "-1"
        }

        return (
            <div className="container mx-auto px-5 xl:px-12 relative max-w-lg md:max-w-fit pt-20 pb-20">
                <div className="hidden md:block" style={leftHalfCircle}></div>

                <div className="bg-lndblue text-white rounded-lg relative z-10">
                    <div className="flex flex-col md:flex-row justify-between">
                        <div className="">
                            <p className="text-center md:text-left text-2xl xl:text-4xl font-bold mx-4 md:mx-5 md:mt-8 lg:mx-20 mt-4 lg:mt-20">{this.props.bannerData.bottomBannerTitle}</p>
                            <div className="text-center md:text-left text-sm xl:text-base mx-auto lg:mx-20 mt-5 md:mt-5 md:mx-5 lg:mt-10 max-w-sm" dangerouslySetInnerHTML={{
                                __html: this.props.bannerData.bottomBannerBody.childMarkdownRemark.html,
                            }} />
                            <div className="mx-4 md:mx-20 mt-2 mb-2 md:mb-0 md:mt-3 text-center md:text-left">
                                <RedButton buttonPage="/services" buttonText="View All Services" title="View All Services" alt="View post collision services offered by L&D Solutions" />
                            </div>
                        </div>

                        <div className="md:w-1/2 relative lg:w-fit">
                            <GatsbyImage image={this.props.bannerData.bannerImage.gatsbyImageData} className="lg:w-[500px] mx-auto" title="L&D Solutions Repairs" alt="L&D Solutions Repairs And Services" loading="eager" placeholder="none" />
                            <div className="hidden md:block" style={rightHalfCircle}></div>
                            <div className="md:hidden" style={mobileRightHalfCircle}></div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

