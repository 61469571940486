import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import GoogleIcon from "../icons/google-icon"

const reviews = [
    {
        id: 1,
        author: "Jay's PDR and Paint",
        date: "February 20, 2024",
        rating: 5,
        review: "Not only was Russ at L&D Solutions able to professionally rebuild my (2) blown seatbelt assemblies, he offered excellent advice and real insights into our issues as a whole.  Extremely satisfied and happy to have found a company with such expertise.  Thank you, L&D Solutions.",
        avatar: "https://via.placeholder.com/150",
    },
    {
        id: 2,
        author: "Herbie Garza",
        date: "December 16, 2023",
        rating: 5,
        review: "sent my airbag computer for a reset. came back installed 10 days later light came back on. talked to Russ ask me to mail it back. he looked at it reset again . i installed it light did not clear. talked to Russ suggested to find and purchase another box. i did installed and no more lights on dash.excellent company to do buisness with. would recommend to all and would use them again if need arises.  thanks again",
        avatar: "https://via.placeholder.com/150",
    },
    {
        id: 3,
        author: "Antionette Chavers",
        date: "October 10, 2023",
        rating: 5,
        review: "I wrecked my car and my Seatbelt locked I thought indefinitely until I found L&D Solutions. So I just got my car back from the collision repair and the , seatbelts L&D fixed got installed and they’re prefect!! Retraction works, light went off and locking mechanism is like new!!! your turn around was fast as agreed 💯 thanks so much! I’m impressed!!!!",
        avatar: "https://via.placeholder.com/150",
    }
]

const GoogleReviews = () => {
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const settingsSmall = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        customPaging: function (i) {
            return (
                <div
                    className={`h-[6px] w-7 mt-5 rounded-full bg-gray-400 mr-2 ${currentSlide === i ? 'bg-lndred' : ''}`}
                    style={{ opacity: 0.5 }}
                ></div>
            )
        },
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex)
    }

    const settingsLarge = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        customPaging: function (i) {
            return (
                <div
                    className={`h-[6px] w-7 mt-5 rounded-full bg-gray-400 mr-2 ${currentSlide === i ? 'bg-lndred' : ''}`}
                    style={{ opacity: 0.5 }}
                ></div>
            )
        },
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex)
    }

    const settingsExtraLarge = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        customPaging: function (i) {
            return (
                <div
                    className={`h-[6px] w-7 mt-5 rounded-full bg-gray-400 mr-2 ${currentSlide === i ? 'bg-lndred' : ''}`}
                    style={{ opacity: 0.5 }}
                ></div>
            )
        },
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex)
    }

    return (
        <div className="bg-newlndgray pt-20 pb-24 relative">
            <div className="container mx-auto px-5 xl:px-[195px]">
                <h2 className="text-center lg:text-left text-sm text-lndred px-5">Google Reviews</h2>
                <p className="px-5 font-bold text-2xl lg:text-3xl text-center lg:text-left mb-10">Trusted By 1,000's Of Customers Each Year</p>
                <div className="lg:hidden">
                    <Slider {...settingsSmall}>
                        {reviews.map((review) => (
                            <div key={review.id} className="px-4 relative">
                                <div className="bg-white p-6 rounded-lg shadow-md flex items-center" style={{ height: "400px" }}>
                                    <div>
                                        <p className="text-lg font-semibold">{review.author}</p>
                                        <p className="text-gray-500 text-sm mb-2">{review.date}</p>
                                        <div className="flex mb-2">
                                            {[...Array(review.rating)].map((_, index) => (
                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400 fill-current mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 1l2.46 6.755L19 7.755l-5.612 4.628L15.888 19 10 15.875 4.112 19l1.5-6.617L1 7.755l6.54-.19L10 1z" />
                                                </svg>
                                            ))}
                                        </div>
                                        <p className="text-gray-700">{review.review}</p>
                                    </div>
                                    <div className="absolute top-6 right-12 w-2 h-2" >
                                        <GoogleIcon />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                <div className="hidden lg:block 2xl:hidden">
                    <Slider {...settingsLarge}>
                        {reviews.map((review) => (
                            <div key={review.id} className="px-4 relative">
                                <div className="bg-white p-6 rounded-lg shadow-md flex items-center" style={{ height: "400px" }}>
                                    <div>
                                        <p className="text-lg font-semibold">{review.author}</p>
                                        <p className="text-gray-500 text-sm mb-2">{review.date}</p>
                                        <div className="flex mb-2">
                                            {[...Array(review.rating)].map((_, index) => (
                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400 fill-current mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 1l2.46 6.755L19 7.755l-5.612 4.628L15.888 19 10 15.875 4.112 19l1.5-6.617L1 7.755l6.54-.19L10 1z" />
                                                </svg>
                                            ))}
                                        </div>
                                        <p className="text-gray-700">{review.review}</p>
                                    </div>
                                    <div className="absolute top-6 right-12 w-2 h-2" >
                                        <GoogleIcon />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                <div className="hidden 2xl:block">
                    <Slider {...settingsExtraLarge}>
                        {reviews.map((review) => (
                            <div key={review.id} className="px-4 relative">
                                <div className="bg-white p-6 rounded-lg shadow-md flex items-center" style={{ height: "400px" }}>
                                    <div>
                                        <p className="text-lg font-semibold">{review.author}</p>
                                        <p className="text-gray-500 text-sm mb-2">{review.date}</p>
                                        <div className="flex mb-2">
                                            {[...Array(review.rating)].map((_, index) => (
                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400 fill-current mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 1l2.46 6.755L19 7.755l-5.612 4.628L15.888 19 10 15.875 4.112 19l1.5-6.617L1 7.755l6.54-.19L10 1z" />
                                                </svg>
                                            ))}
                                        </div>
                                        <p className="text-gray-700">{review.review}</p>
                                    </div>
                                    <div className="absolute top-6 right-12 w-2 h-2" >
                                        <GoogleIcon />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}
export default GoogleReviews;